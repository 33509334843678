import gql from 'graphql-tag';
import { getCustomer, getSubscriptionForCustomer } from 'graphqlLiterals/rest';
import { getApolloClient } from 'helpers/functions';
import auth from 'reactiveVars/auth';
import { redirectOnLoader } from './routing';

const broadChannel = new BroadcastChannel('broad_channel');

export const isAuthenticated = () => {
  return auth().accessToken && !expiresSoon(30);
};

export const expiresSoon = (secondsBeforeExpiration) => {
  const parts = auth().accessToken.split('.');

  if (parts.length !== 3) {
    return true;
  }
  const exp = JSON.parse(atob(parts[1])).exp;

  if (exp === null) {
    return true;
  }
  if (secondsBeforeExpiration === undefined) {
    secondsBeforeExpiration = 3 * 60;
  }
  return Math.floor(Date.now() / 1000) + secondsBeforeExpiration >= exp;
};

export const getNooliId = () => {
  const parts = auth().accessToken.split('.');

  if (parts.length !== 3) {
    throw new Error('Invalid JWT format');
  }

  return JSON.parse(atob(parts[1])).sub;
};

export const get = () => {
  const parts = auth().accessToken.split('.');

  if (parts.length !== 3) {
    throw new Error('Invalid JWT format');
  }

  return JSON.parse(atob(parts[1])).sub;
};

export const login = () => {
  broadChannel.postMessage({ type: 'login' });
};

export const logout = (reloadPage = true) => {
  handleLogOutEventInAllTabs(reloadPage);

  broadChannel.postMessage({ type: 'logout' });
};
export const handleLogOutEventInAllTabs = (reloadPage = true) => {
  getApolloClient().cache.reset();

  localStorage.clear();

  if (reloadPage) {
    window.location.reload();
  }
};

export const subscribeToAuthEvents = () => {
  broadChannel.onmessage = (event) => {
    if (event.data.type === 'logout') {
      handleLogOutEventInAllTabs();
    } else if (event.data.type === 'login') {
      window.location.reload();
    }
  };
};

export const getCustomerSubscription = async () => {
  const client = getApolloClient();
  const getCustomerSubscription = await client.query({
    query: gql(getSubscriptionForCustomer),
    variables: {
      params: {
        customer_id: getNooliId(),
      },
    },
  });

  auth({
    ...auth(),
    hasSubscription: Boolean(
      getCustomerSubscription?.data?.getSubscriptionForCustomer?.subscription
    ),
  });

  return getCustomerSubscription;
};

export const checkSubscriptionAndReturnDataOrRedirect = () => {
  const data = getCustomerSubscription().then((res) => {
    if (
      !(
        res.data.getSubscriptionForCustomer?.customer?.first_name &&
        res.data.getSubscriptionForCustomer?.customer?.last_name &&
        res.data.getSubscriptionForCustomer?.customer?.email
      )
    ) {
      return redirectOnLoader('/signup/personal-details');
    } else if (auth().hasSubscription) {
      return res;
    } else {
      return redirectOnLoader('/signup/set-up-subscription');
    }
  });

  return data;
};

export const hasPersonalDetails = async () => {
  const client = getApolloClient();

  const response = await client.query({
    query: gql(getCustomer),
  });

  return (
    response.data.getCustomer.customer.first_name &&
    response.data.getCustomer.customer.last_name &&
    response.data.getCustomer.customer.email
  );
};

export const getNooliIdOrNull = (accessToken) => {
  const parts = (accessToken ?? auth().accessToken)?.split('.');
  if (!parts || parts.length !== 3) {
    return null;
  }
  return JSON.parse(atob(parts[1])).sub;
};
