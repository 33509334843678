import { experimentsEnabled, screensToExperiment } from 'constants/experiments';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useIntiChargeBee = () => {
  const [initiated, setInitiatedStatus] = useState(window.Chargebee?.inited);
  const [cbInstance, setCbInstance] = useState(null);
  useEffect(() => {
    if (!initiated) {
      window.Chargebee?.init({
        site: import.meta.env.VITE_CHARGEEBEE_SITE,
        publishableKey: import.meta.env.VITE_CHARGEEBEE_PUBLISH_KEY,
      });

      setInitiatedStatus(window.Chargebee?.inited);
    }
  }, [initiated]);

  useEffect(() => {
    if (initiated) {
      setCbInstance(window.Chargebee?.getInstance());
    }
  }, [initiated]);

  return {
    ready: initiated,
    chargeBee: window.Chargebee,
    cbInstance,
  };
};

export const useExperimentSetter = () => {
  const [searchParams] = useSearchParams();
  const [experimentAllowed, setExperimentAllowed] = useState(false);

  useEffect(() => {
    if (experimentsEnabled[searchParams.get('v')]) {
      setExperimentAllowed(
        screensToExperiment[searchParams.get('v')]?.every((screen) => {
          const experimentVersion = localStorage.getItem(
            `experiment-${screen}`
          );
          if (experimentVersion) {
            return !experimentsEnabled[experimentVersion];
          }
          return true;
        }) ?? false
      );
    }
  }, [searchParams]);

  useEffect(() => {
    if (experimentAllowed) {
      screensToExperiment[searchParams.get('v')]?.every((screen) => {
        return !localStorage.setItem(
          `experiment-${screen}`,
          searchParams.get('v')
        );
      });
    }
  }, [experimentAllowed, searchParams]);
};
