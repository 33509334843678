import { Box } from '@mui/material';
import React from 'react';
import FooterImage from './footerImage';

const AppWrapper = ({ children }) => {
  return (
    <Box height={1} display="flex" flexDirection="column">
      <Box flexGrow={1}>{children}</Box>
      <Box>
        <FooterImage />
      </Box>
    </Box>
  );
};

export default AppWrapper;
