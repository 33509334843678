import { createTheme } from '@mui/material/styles';
import colors from 'assets/defs/colors';
import PlusJakartaSansExtraBoldTruetype from 'assets/fonts/PlusJakartaSans-ExtraBold.ttf';
import PlusJakartaSansRegularTruetype from 'assets/fonts/PlusJakartaSans-Regular.ttf';
import PlusJakartaSansMediumTruetype from 'assets/fonts/PlusJakartaSans-Medium.ttf';
import PlusJakartaSansSemiBoldTruetype from 'assets/fonts/PlusJakartaSans-SemiBold.ttf';
import PlusJakartaSansBoldTruetype from 'assets/fonts/PlusJakartaSans-Bold.ttf';
import PlusJakartaSansItalicTruetype from 'assets/fonts/PlusJakartaSans-Italic.ttf';
import PlusJakartaSansBoldItalicTruetype from 'assets/fonts/PlusJakartaSans-BoldItalic.ttf';

import LoraRegularTruetype from 'assets/fonts/Lora-Regular.ttf';
import LoraMediumTruetype from 'assets/fonts/Lora-Medium.ttf';
import LoraSemiBoldTruetype from 'assets/fonts/Lora-SemiBold.ttf';
import LoraBoldTruetype from 'assets/fonts/Lora-Bold.ttf';
import LoraItalicTruetype from 'assets/fonts/Lora-Italic.ttf';
import LoraBoldItalicTruetype from 'assets/fonts/Lora-BoldItalic.ttf';
import loadingIndicator from 'assets/icons/loadingIndicator.svg';

const whiteTheme = createTheme({
  spacing: 4,
  palette: {
    colors: {
      white: colors.nlWhite,
      warmGrey: colors.nlWarmGrey,
      backgroundGrey: colors.nlBackgroundGrey,
      darkPurple: colors.nlDarkPurple,
      darkPurple80: colors.nlDarkPurple80,
      darkPurple60: colors.nlDarkPurple60,
      darkPurple40: colors.nlDarkPurple40,
      darkPurple20: colors.nlDarkPurple20,
      darkPurple10: colors.nlDarkPurple10,

      brightPurple: colors.nlBrightPurple,
      brightPurple80: colors.nlBrightPurple80,
      brightPurple60: colors.nlBrightPurple60,
      brightPurple40: colors.nlBrightPurple40,
      brightPurple20: colors.nlBrightPurple20,
      brightPurple10: colors.nlBrightPurple10,

      black: colors.nlBlack,
      black80: colors.nlBlack80,
      black60: colors.nlBlack60,
      black40: colors.nlBlack40,
      black20: colors.nlBlack20,
      black10: colors.nlBlack10,

      brightTurquoise: colors.nlBrightTurquoise,
      brightTurquoise80: colors.nlBrightTurquoise80,
      brightTurquoise60: colors.nlBrightTurquoise60,
      brightTurquoise40: colors.nlBrightTurquoise40,
      brightTurquoise20: colors.nlBrightTurquoise20,
      brightTurquoise10: colors.nlBrightTurquoise10,

      darkTurquoise: colors.nlDarkTurquoise,
      darkTurquoise80: colors.nlDarkTurquoise80,
      darkTurquoise60: colors.nlDarkTurquoise60,
      darkTurquoise40: colors.nlDarkTurquoise40,
      darkTurquoise20: colors.nlDarkTurquoise20,
      darkTurquoise10: colors.nlDarkTurquoise10,

      brightViolet: colors.nlBrightViolet,
      brightViolet80: colors.nlBrightViolet80,
      brightViolet60: colors.nlBrightViolet60,
      brightViolet40: colors.nlBrightViolet40,
      brightViolet20: colors.nlBrightViolet20,
      brightViolet10: colors.nlBrightViolet10,

      brightGreen: colors.nlBrightGreen,
      brightGreen80: colors.nlBrightGreen80,
      brightGreen60: colors.nlBrightGreen60,
      brightGreen40: colors.nlBrightGreen40,
      brightGreen20: colors.nlBrightGreen20,
      brightGreen10: colors.nlBrightGreen10,

      brightRed: colors.nlBrightRed,
      brightRed80: colors.nlBrightRed80,
      brightRed60: colors.nlBrightRed60,
      brightRed40: colors.nlBrightRed40,
      brightRed20: colors.nlBrightRed20,
      brightRed10: colors.nlBrightRed10,

      error: colors.nlError,
      error80: colors.nlError80,
      error60: colors.nlError60,
      error40: colors.nlError40,
      error20: colors.nlError20,
      error10: colors.nlError10,

      alert: colors.nlAlert,
      alert80: colors.nlAlert80,
      alert60: colors.nlAlert60,
      alert40: colors.nlAlert40,
      alert20: colors.nlAlert20,
      alert10: colors.nlAlert10,

      brightYellow: colors.nlBrightYellow,
      brightYellow80: colors.nlBrightYellow80,
      brightYellow60: colors.nlBrightYellow60,
      brightYellow40: colors.nlBrightYellow40,
      brightYellow20: colors.nlBrightYellow20,
      brightYellow10: colors.nlBrightYellow10,

      confirmation: colors.nlConfirmation,
      confirmation80: colors.nlConfirmation80,
      confirmation60: colors.nlConfirmation60,
      confirmation40: colors.nlConfirmation40,
      confirmation20: colors.nlConfirmation20,
      confirmation10: colors.nlConfirmation10,

      link: colors.nlLink,
      link80: colors.nlLink,
      link60: colors.nlLink,
      link40: colors.nlLink,
      link20: colors.nlLink,
      link10: colors.nlLink,
    },

    primary: {
      main: colors.nlDarkPurple,
    },
    appBar: {
      main: colors.nlWhite,
      contrastText: colors.nlBlack60,
    },
    secondary: {
      main: colors.nlBlack,
    },
    error: {
      main: colors.nlError,
    },
    background: {
      default: colors.nlBackgroundGrey,
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'appBar',
      },
      styleOverrides: {
        root: {
          boxShadow: 'none',
          padding: '41px 16px 7px 16px',
          position: 'sticky',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: '0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexShrink: '0',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          height: '100%',
          margin: '0px',
        },
        html: {
          height: '100%',
        },

        '#root': {
          height: '100%',
        },
        '#apple-pay-button': {
          height: '56px',
        },
        header: {
          boxShadow: 'none',
        },
        '@font-face': {
          fontFamily: 'Lora-Regular',
          src: `url(${LoraRegularTruetype}) format('truetype')`,
        },
        fallbacks: [
          {
            '@font-face': {
              fontFamily: 'Lora-Medium',
              src: `url(${LoraMediumTruetype}) format('truetype')`,
            },
          },
          {
            '@font-face': {
              fontFamily: 'Lora-SemiBold',
              src: `url(${LoraSemiBoldTruetype}) format('truetype')`,
            },
          },

          {
            '@font-face': {
              fontFamily: 'Lora-Bold',
              src: `url(${LoraBoldTruetype}) format('truetype')`,
            },
          },
          {
            '@font-face': {
              fontFamily: 'Lora-Italic',
              src: `url(${LoraItalicTruetype}) format('truetype')`,
            },
          },
          {
            '@font-face': {
              fontFamily: 'Lora-BoldItalic',
              src: `url(${LoraBoldItalicTruetype}) format('truetype')`,
            },
          },
          {
            '@font-face': {
              fontFamily: 'PlusJakartaSans-ExtraBold',
              src: `url(${PlusJakartaSansExtraBoldTruetype}) format('truetype')`,
            },
          },
          {
            '@font-face': {
              fontFamily: 'PlusJakartaSans-Regular',
              src: `url(${PlusJakartaSansRegularTruetype}) format('truetype')`,
            },
          },
          {
            '@font-face': {
              fontFamily: 'PlusJakartaSans-Medium',
              src: `url(${PlusJakartaSansMediumTruetype}) format('truetype')`,
            },
          },
          {
            '@font-face': {
              fontFamily: 'PlusJakartaSans-SemiBold',
              src: `url(${PlusJakartaSansSemiBoldTruetype}) format('truetype')`,
            },
          },
          {
            '@font-face': {
              fontFamily: 'PlusJakartaSans-Bold',
              src: `url(${PlusJakartaSansBoldTruetype}) format('truetype')`,
            },
          },
          {
            '@font-face': {
              fontFamily: 'PlusJakartaSans-Italic',
              src: `url(${PlusJakartaSansItalicTruetype}) format('truetype')`,
            },
          },
          {
            '@font-face': {
              fontFamily: 'PlusJakartaSans-BoldItalic',
              src: `url(${PlusJakartaSansBoldItalicTruetype}) format('truetype')`,
            },
          },
        ],
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '8px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        themevariant: 'single-line-input',
        fullWidth: true,
      },
      variants: [
        {
          props: { themevariant: 'single-line-input' },
          style: {
            '& .MuiInputLabel-root': {
              transform: 'initial',
              position: 'initial',
              fontFamily: 'PlusJakartaSans-Regular',
              color: colors.nlBlack,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.2px',
              fontWeight: 700,
              marginBottom: '8px',
              border: 'none',
              outline: 'none',
              '&.Mui-error': {
                border: 'none',
                outline: 'none',
                color: colors.nlBlack,
              },
              '&.Mui-focused': {
                border: 'none',
                outline: 'none',
                color: colors.nlBlack,
              },
            },
            '& .MuiInputBase-adornedStart.Mui-disabled ': {
              backgroundColor: colors.nlBackgroundGrey,
              border: 'none',
            },
            //Root styles

            '& .MuiInputBase-adornedStart .MuiInputBase-input': {
              paddingLeft: '4px',
            },

            '& .MuiInputBase-input': {
              padding: '16px',
              borderRadius: '4px',
              fontFamily: 'PlusJakartaSans-Regular',
              color: colors.nlBlack,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.2px',
              '&.MuiOutlinedInput-input::placeholder': {
                opacity: '1 !important',
                color: colors.nlBlack40,
              },
              '&.MuiOutlinedInput-input:not(:focus)::placeholder': {
                opacity: '1 !important',
                color: colors.nlBlack40,
              },
            },
            '& .MuiOutlinedInput-root': {
              border: '1px solid #FFFFFF',
              backgroundColor: '#FFFFFF',
              outline: '1px solid #DCD7E8',

              '& .Mui-disabled': {
                backgroundColor: colors.nlBackgroundGrey,
                border: 'none',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },

            // Focused & Hover state styles
            '& .Mui-focused': {
              border: '1px solid #4E358C',
              borderRadius: '4px',
              outline: '1px solid #4E358C',
            },
            '&:hover': {
              // backgroundColor:'#EDEBF3',
            },

            //Error states styles
            '& .Mui-error': {
              border: '1px solid #DC091B',
              color: '#DC091B',
              outline: '1px solid #DC091B',
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
              outline: 'none',
              margin: '8px 0px 0px 0px ',
              fontSize: '14px',
              fontFamily: 'PlusJakartaSans-Regular',
              lineHeight: '20px',
              letterSpacing: '0.1px',
              fontWeight: 500,
            },

            //Disabled state style
            '& .Mui-disabled': {
              borderColor: '#F2F2F7',
              color: colors.nlBlack,
            },
          },
        },
        {
          props: { themevariant: 'single-line-button-input' },
          style: {
            '& .MuiInputLabel-root': {
              transform: 'initial',
              position: 'initial',
              fontFamily: 'PlusJakartaSans-Regular',
              color: colors.nlBlack,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.2px',
              fontWeight: 700,
              marginBottom: '8px',
              border: 'none',
              outline: 'none',
              '&.Mui-error': {
                border: 'none',
                outline: 'none',
                color: colors.nlBlack,
              },
              '&.Mui-focused': {
                border: 'none',
                outline: 'none',
                color: colors.nlBlack,
              },
            },
            //Root styles

            '& .MuiInputBase-adornedStart.Mui-disabled ': {
              backgroundColor: colors.nlBackgroundGrey,
              border: 'none',
            },

            '& .MuiInputBase-input': {
              padding: '16px',
              paddingLeft: '0px',
              borderRadius: '4px',
              fontFamily: 'PlusJakartaSans-Regular',
              color: colors.nlBlack,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.2px',
              '&.MuiOutlinedInput-input::placeholder': {
                opacity: '1 !important',
                color: colors.nlBlack40,
              },
              '&.MuiOutlinedInput-input:not(:focus)::placeholder': {
                opacity: '1 !important',
                color: colors.nlBlack40,
              },

              '&.MuiOutlinedInput-input.Mui-disabled': {
                backgroundColor: colors.nlBackgroundGrey,
                border: 'none',
              },
            },

            '& .MuiOutlinedInput-root': {
              border: '1px solid #DCD7E8',
              backgroundColor: '#FFFFFF',
              outline: '1px solid #DCD7E8',
              paddingRight: '0px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
              paddingRight: '0px',
            },

            // Focused & Hover state styles
            '& .Mui-focused': {
              border: '1px solid #4E358C',
              borderRadius: '4px',
              outline: '1px solid #4E358C',
            },
            '&:hover': {
              // backgroundColor:'#EDEBF3',
            },

            //Error states styles
            '& .Mui-error': {
              border: '1px solid #DC091B',
              color: '#DC091B',
              outline: '1px solid #DC091B',
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
              outline: 'none',
              margin: '8px 0px 0px 0px ',
              fontSize: '14px',
              fontFamily: 'PlusJakartaSans-Regular',
              lineHeight: '20px',
              letterSpacing: '0.1px',
              fontWeight: 500,
            },

            //Disabled state style
            // '& .Mui-disabled': {
            //   borderColor: '#F2F2F7',
            //   color: colors.nlBlack,
            // },
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableRipple: true,
        themevariant: 'primary',
        loading: false,
        disabled: false,
      },
      variants: [
        {
          props: { themevariant: 'primary' },
          style: {
            display: 'flex',
            padding: '16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            width: '100%',
            borderRadius: '8px',
            backgroundColor: colors.nlDarkPurple,
            color: colors.nlWhite,
            stroke: colors.nlWhite,
            fontFamily: 'PlusJakartaSans-Bold',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.2px',
            fontWeight: 700,
            textTransform: 'none',
            boxShadow: 'none',

            '&:hover': {
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${colors.nlDarkPurple}`,
              color: colors.nlWhite,
              stroke: colors.nlWhite,
              boxShadow: 'none',
            },

            '&:focus-visible': {
              backgroundColor: colors.nlBlack,
              color: colors.nlWhite,
              stroke: colors.nlWhite,
            },
            '&:active': {
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), ${colors.nlDarkPurple}`,
              color: colors.nlWhite,
              stroke: colors.nlWhite,
            },
            '&:disabled': {
              backgroundColor: colors.nlBlack10,
              color: colors.nlBlack40,
              stroke: colors.nlDarkPurple80,
              boxShadow: 'none',
            },
          },
        },
        {
          props: { themevariant: 'secondary' },
          style: {
            display: 'flex',
            padding: '16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            width: '100%',
            borderRadius: '8px',
            fontFamily: 'PlusJakartaSans-Bold',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.2px',
            fontWeight: 700,
            backgroundColor: colors.nlDarkPurple10,
            color: colors.nlDarkPurple,
            stroke: colors.nlDarkPurple,
            textTransform: 'none',
            boxShadow: 'none',

            '&:hover': {
              backgroundColor: colors.nlDarkPurple60,
              color: colors.nlDarkPurple,
              stroke: colors.nlDarkPurple,
              boxShadow: 'none',
            },
            '&:focus-visible': {
              backgroundColor: colors.nlBlack,
              color: colors.nlWhite,
              stroke: colors.nlWhite,
            },
            '&:active': {
              background: colors.nlDarkPurple80,
              color: colors.nlWhite,
              stroke: colors.nlWhite,
            },
            '&:disabled': {
              backgroundColor: colors.nlDarkPurple20,
              color: colors.nlDarkPurple80,
              stroke: colors.nlDarkPurple80,
              boxShadow: 'none',
            },
          },
        },

        {
          props: { themevariant: 'tertiary' },
          style: {
            display: 'flex',
            padding: '16px',
            justifyContent: 'center',
            borderRadius: '8px',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            fontFamily: 'PlusJakartaSans-Bold',
            width: '100%',
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
            letterSpacing: '0.2px',
            textTransform: 'none',
            backgroundColor: colors.nlWhite,
            color: colors.nlDarkPurple,
            stroke: colors.nlDarkPurple,
            boxShadow: 'none',

            '&:hover': {
              backgroundColor: colors.nlDarkPurple60,
              color: colors.nlDarkPurple,
              stroke: colors.nlDarkPurple,
              boxShadow: 'none',
            },
            '&:focus-visible': {
              backgroundColor: colors.nlBlack,
              color: colors.nlWhite,
              stroke: colors.nlWhite,
            },
            '&:active': {
              background: colors.nlDarkPurple80,
              color: colors.nlWhite,
              stroke: colors.nlWhite,
            },
            '&:disabled': {
              backgroundColor: colors.nlDarkPurple20,
              color: colors.nlDarkPurple80,
              stroke: colors.nlDarkPurple80,
              boxShadow: 'none',
            },
          },
        },

        {
          props: { themevariant: 'error' },
          style: {
            display: 'flex',
            padding: '16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            width: '100%',
            borderRadius: '8px',
            fontFamily: 'PlusJakartaSans-Regular',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.2px',
            fontWeight: 700,
            backgroundColor: colors.nlWhite,
            color: colors.nlError,
            stroke: colors.nlError,
            textTransform: 'none',
            boxShadow: 'none',

            '&:hover': {
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) ${colors.nlWhite}`,
              color: colors.nlError,
              stroke: colors.nlError,
              boxShadow: 'none',
              '&:hover': {
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) ${colors.nlWhite}`,
                color: colors.nlError,
                stroke: colors.nlError,
              },
            },
          },
        },
        {
          props: { themevariant: 'primary-small' },
          style: {
            display: 'flex',
            padding: '8px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: '0',
            maxWidth: '190px',
            gap: '8px',
            alignSelf: 'stretch',
            borderRadius: '32px',
            backgroundColor: colors.nlDarkPurple,
            color: colors.nlWhite,
            stroke: colors.nlWhite,
            fontFamily: 'PlusJakartaSans-Bold',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 700,
            textTransform: 'none',
            boxShadow: 'none',

            '&:hover': {
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${colors.nlDarkPurple}`,
              color: colors.nlWhite,
              boxShadow: 'none',
              stroke: colors.nlWhite,
            },

            '&:focus-visible': {
              backgroundColor: colors.nlBlack,
              color: colors.nlWhite,
              boxShadow: 'none',
              stroke: colors.nlWhite,
            },
            '&:active': {
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), ${colors.nlDarkPurple}`,
              color: colors.nlWhite,
              boxShadow: 'none',
              stroke: colors.nlWhite,
            },
            '&:disabled': {
              backgroundColor: colors.nlBlack10,
              color: colors.nlBlack40,
              stroke: colors.nlDarkPurple80,
            },
          },
        },
        {
          props: {
            loading: true,
            disabled: true,
          },
          style: {
            // visibility: 'hidden',
            '&::after': {
              content: `url(${loadingIndicator})`,
              position: 'absolute',
              height: '100%',
              width: '100%',
              backgroundColor: 'inherit',
              overflow: 'hidden',
              borderRadius: 'inherit',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              lineHeight: '0px',
            },
          },
        },
        {
          props: {
            loading: true,
            disabled: true,
            themevariant: 'primary',
          },
          style: {
            '&:disabled': {
              backgroundColor: colors.nlDarkPurple,
              color: colors.nlWhite,
              stroke: colors.nlWhite,
              boxShadow: 'none',
            },
          },
        },
        {
          props: { themevariant: 'secondary-small' },
          style: {
            display: 'flex',
            padding: '8px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: '0',
            maxWidth: '190px',
            gap: '8px',
            alignSelf: 'stretch',
            borderRadius: '32px',
            backgroundColor: colors.nlDarkPurple20,
            color: colors.nlDarkPurple,
            stroke: colors.nlDarkPurple,
            fontFamily: 'PlusJakartaSans-Bold',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 700,
            textTransform: 'none',
            boxShadow: 'none',

            '&:hover': {
              backgroundColor: colors.nlDarkPurple60,
              color: colors.nlDarkPurple,
              stroke: colors.nlDarkPurple,
              boxShadow: 'none',
            },
            '&:focus-visible': {
              backgroundColor: colors.nlBlack,
              color: colors.nlWhite,
              stroke: colors.nlWhite,
              boxShadow: 'none',
            },
            '&:active': {
              background: colors.nlDarkPurple80,
              color: colors.nlWhite,
              stroke: colors.nlWhite,
              boxShadow: 'none',
            },
            '&:disabled': {
              backgroundColor: colors.nlDarkPurple20,
              color: colors.nlDarkPurple80,
              stroke: colors.nlDarkPurple80,
              boxShadow: 'none',
            },
          },
        },
        {
          props: {
            loading: true,
            disabled: true,
          },
          style: {
            // visibility: 'hidden',
            '&::after': {
              content: `url(${loadingIndicator})`,
              position: 'absolute',
              height: '100%',
              width: '100%',
              backgroundColor: 'inherit',
              overflow: 'hidden',
              borderRadius: 'inherit',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              lineHeight: '0px',
            },
            '&.applyButton::after': {
              border: '1px solid #4E358C',
              outline: '1px solid #4E358C',
              lineHeight: 0,
            },
          },
        },

        {
          props: {
            loading: true,
            disabled: true,
            themevariant: 'single-line-input',
          },
          style: {
            // visibility: 'hidden',
            '&::after': {
              content: `url(${loadingIndicator})`,
              position: 'absolute',
              height: '100%',
              width: '100%',
              backgroundColor: 'inherit',
              overflow: 'hidden',
              borderRadius: 'inherit',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
        },
        {
          props: {
            loading: true,
            disabled: true,
            themevariant: 'primary',
          },
          style: {
            '&:disabled': {
              backgroundColor: colors.nlDarkPurple,
              color: colors.nlWhite,
              stroke: colors.nlWhite,
              boxShadow: 'none',
            },
          },
        },
        {
          props: {
            loading: true,
            disabled: true,
            themevariant: 'secondary',
          },
          style: {
            '&:disabled': {
              backgroundColor: colors.nlDarkPurple10,
              color: colors.nlDarkPurple,
              stroke: colors.nlDarkPurple,
            },
          },
        },
        {
          props: {
            loading: true,
            disabled: true,
            themevariant: 'error',
          },
          style: {
            '&:disabled': {
              color: colors.nlError,
              stroke: colors.nlError,
              fill: colors.nlError,
            },
          },
        },
        {
          props: {
            loading: true,
            disabled: true,
            themevariant: 'tertiary',
          },
          style: {
            '&:disabled': {
              backgroundColor: colors.nlWhite,
              color: colors.nlDarkPurple,
              stroke: colors.nlDarkPurple,
            },
          },
        },
      ],
    },

    MuiCircularProgress: {
      defaultProps: {
        style: {
          height: '24.5px',
          width: '24.5px',
          color: 'inherit',
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'extraSuperTitle' },
          style: {
            fontFamily: 'PlusJakartaSans-Medium',
            color: colors.nlBlack,
            fontSize: '34px',
            lineHeight: '32px',
            letterSpacing: '0.2px',
          },
        },
        {
          props: { variant: 'superTitle' },
          style: {
            fontFamily: 'Lora-Bold',
            color: colors.nlDarkPurple,
            fontSize: '32px',
            lineHeight: '40px',
            letterSpacing: '0px',
          },
        },
        {
          props: { variant: 'title1' },
          style: {
            fontFamily: 'Lora-Regular',
            color: colors.nlDarkPurple,
            fontSize: '28px',
            lineHeight: '36px',
            letterSpacing: '0px',
          },
        },
        {
          props: { variant: 'title2' },
          style: {
            fontFamily: 'Lora-Regular',
            color: colors.nlDarkPurple,
            fontSize: '24px',
            lineHeight: '32px',
            letterSpacing: '0px',
          },
        },
        {
          props: { variant: 'title3' },
          style: {
            fontFamily: 'Lora-Regular',
            color: colors.nlDarkPurple,
            fontSize: '20px',
            lineHeight: '26px',
            letterSpacing: '0px',
          },
        },
        {
          props: { variant: 'title4' },
          style: {
            fontFamily: 'PlusJakartaSans-Regular',
            color: colors.nlBlack,
            fontSize: '24px',
            lineHeight: '32px',
            letterSpacing: '0.2px',
          },
        },
        {
          props: { variant: 'title5' },
          style: {
            fontFamily: 'PlusJakartaSans-Regular',
            color: colors.nlBlack,
            fontSize: '26px',
            lineHeight: '34px',
            letterSpacing: '0.2px',
          },
        },
        {
          props: { variant: 'title5-extra-bold' },
          style: {
            fontFamily: 'PlusJakartaSans-ExtraBold',
            color: colors.nlBlack,
            fontSize: '26px',
            lineHeight: '34px',
            letterSpacing: '0.2px',
          },
        },
        {
          props: { variant: 'headline1' },
          style: {
            fontFamily: 'PlusJakartaSans-Regular',
            color: colors.nlBlack,
            fontSize: '20px',
            lineHeight: '26px',
            letterSpacing: '0.2px',
          },
        },
        {
          props: { variant: 'headline1-bold' },
          style: {
            fontFamily: 'PlusJakartaSans-Bold',
            color: colors.nlBlack,
            fontSize: '20px',
            lineHeight: '26px',
            letterSpacing: '0.2px',
          },
        },
        {
          props: { variant: 'headlilne2' },
          style: {
            fontFamily: 'PlusJakartaSans-Regular',
            color: colors.nlBlack,
            fontSize: '16px',
            lineHeight: '20px',
            letterSpacing: '0px',
          },
        },
        {
          props: { variant: 'headline3' },
          style: {
            fontFamily: 'PlusJakartaSans-Regular',
            color: colors.nlBlack,
            fontSize: '17px',
            lineHeight: '21px',
            letterSpacing: '0.034px',
          },
        },
        {
          props: { variant: 'subheadline' },
          style: {
            fontFamily: 'PlusJakartaSans-Regular',
            color: colors.nlBlack,
            fontSize: '15px',
            lineHeight: '23px',
            letterSpacing: '0px',
          },
        },
        {
          props: { variant: 'body1' },
          style: {
            fontFamily: 'PlusJakartaSans-Regular',
            color: colors.nlBlack,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.2px',
          },
        },
        {
          props: { variant: 'body1-bold' },
          style: {
            fontFamily: 'PlusJakartaSans-Bold',
            color: colors.nlBlack,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.2px',
          },
        },
        {
          props: { variant: 'body1-link' },
          style: {
            fontFamily: 'PlusJakartaSans-Regular',
            color: colors.nlLink,
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
            letterSpacing: '0.2px',
          },
        },
        {
          props: { variant: 'body2' },
          style: {
            fontFamily: 'PlusJakartaSans-Regular',
            color: colors.nlBlack,
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.1px',
          },
        },
        {
          props: { variant: 'body2-bold' },
          style: {
            fontFamily: 'PlusJakartaSans-Bold',
            color: colors.nlBlack,
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.1px',
          },
        },
        {
          props: { variant: 'body2-link' },
          style: {
            fontFamily: 'PlusJakartaSans-medium',
            color: colors.nlBlack,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 500,
            letterSpacing: '0.1px',
          },
        },
        {
          props: { variant: 'caption1' },
          style: {
            fontFamily: 'PlusJakartaSans-Regular',
            color: colors.nlBlack,
            fontSize: '12px',
            lineHeight: '20px',
            letterSpacing: '0px',
          },
        },
        {
          props: { variant: 'caption2' },
          style: {
            fontFamily: 'PlusJakartaSans-Regular',
            color: colors.nlBlack,
            fontSize: '10px',
            lineHeight: '20px',
            letterSpacing: '0px',
          },
        },
        {
          props: { variant: 'callout' },
          style: {
            fontFamily: 'PlusJakartaSans-Regular',
            color: colors.nlDarkPurple,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0px',
          },
        },
        {
          props: { variant: 'footnote' },
          style: {
            fontFamily: 'PlusJakartaSans-Regular',
            color: colors.nlBlack,
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0px',
          },
        },
        {
          props: { size: 'bold' },
          style: {
            fontWeight: 700,
          },
        },
        {
          props: { size: 'extra-bold' },
          style: {
            fontWeight: 800,
          },
        },
      ],
    },
  },
});

export default whiteTheme;
