export default {
  nlWhite: '#ffffff',
  nlWarmGrey: '#F4F2F2',
  nlBackgroundGrey: '#F2F2F7',

  nlDarkPurple: '#4E358C',
  nlDarkPurple80: '#715DA3',
  nlDarkPurple60: '#9586BA',
  nlDarkPurple40: '#B8AED1',
  nlDarkPurple20: '#DCD7E8',
  nlDarkPurple10: '#EDEBF3',

  nlBrightPurple: '#BA6AD2',
  nlBrightPurple80: '#D5A5E5',
  nlBrightPurple60: '#E0BCEB',
  nlBrightPurple40: '#EAD2F2',
  nlBrightPurple20: '#F5E9F8',
  nlBrightPurple10: '#FAF4FC',

  nlBlack: '#292929',
  nlBlack80: '#545454',
  nlBlack60: '#7E7E7E',
  nlBlack40: '#A9A9A9',
  nlBlack20: '#D4D4D4',
  nlBlack10: '#EAEAEA',

  nlBrightTurquoise: '#33CBD0',
  nlBrightTurquoise80: '#5CD5D9',
  nlBrightTurquoise60: '#85E0E3',
  nlBrightTurquoise40: '#ADEAEC',
  nlBrightTurquoise20: '#D6F5F6',
  nlBrightTurquoise10: '#EBFAFA',

  nlDarkTurquoise: '#00B1AA',
  nlDarkTurquoise80: '#2FA9A5',
  nlDarkTurquoise60: '#5EBAB7',
  nlDarkTurquoise40: '#8CC9C7',
  nlDarkTurquoise20: '#BBDAD9',
  nlDarkTurquoise10: '#D3E2E2',
  nlBrightViolet: '#8F80FF',
  nlBrightViolet80: '#A599FF',
  nlBrightViolet60: '#BCB3FF',
  nlBrightViolet40: '#BCB3FF',
  nlBrightViolet20: '#E9E6FF',
  nlBrightViolet10: '#F4F2FF',
  nlBrightGreen: '#CBDD52',
  nlBrightGreen80: '#D5E475',
  nlBrightGreen60: '#E0EB97',
  nlBrightGreen40: '#EAF1BA',
  nlBrightGreen20: '#F5F8DC',
  nlBrightGreen10: '#FAFCEE',
  nlBrightRed: '#F7727E',
  nlBrightRed80: '#F98E98',
  nlBrightRed60: '#FAAAB2',
  nlBrightRed40: '#FCC7CB',
  nlBrightRed20: '#FDE3E5',
  nlBrightRed10: '#FEF1F2',

  nlError: '#DC091B',
  nlError80: '#F53B4D',
  nlError60: '#F46977',
  nlError40: '#F397A2',
  nlError20: '#F3C4CC',
  nlError10: '#F2DBE2',

  nlAlert: '#F7A51E',
  nlAlert80: '#F9B74B',
  nlAlert60: '#FAC978',
  nlAlert40: '#FCDBA5',
  nlAlert20: '#FADFBD',
  nlAlert10: '#FEF6E8',

  nlBrightYellow: '#FDBE1B',
  nlBrightYellow80: '#FECB49',
  nlBrightYellow60: '#FED876',
  nlBrightYellow40: '#FEE5A4',
  nlBrightYellow20: '#FFF2D1',
  nlBrightYellow10: '#FFF9E8',

  nlConfirmation: '#207E38',
  nlConfirmation80: '#49945C',
  nlConfirmation60: '#71AA80',
  nlConfirmation40: '#99BEA2',
  nlConfirmation20: '#C1D4C6',
  nlConfirmation10: '#D6E0D9',

  nlLink: '#006ADB',
  nlLink80: '#3388E2',
  nlLink60: '#66A6E9',
  nlLink40: '#99C3F1',
  nlLink20: '#CCE1F8',
  nlLink10: '#E6F1FC',
};
