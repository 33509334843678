import React from 'react';
import Puzzle from 'assets/icons/puzzle.svg';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { logout } from 'helpers/authHelper';
import { getElementId } from 'helpers/functions';

const Errors = (props) => {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    if (props.btnText === 'Refresh page') {
      window.location.reload();
    } else if (props.naviUrl1 === '/') {
      logout();
    } else {
      navigate(props.naviUrl1);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={6}>
      <img
        src={Puzzle}
        alt="balloon"
        style={{ borderRadius: '12px', marginTop: '10px' }}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <p align="center"> {props.firstPara || 'Uh No..'}</p>
        <Typography
          variant="title1"
          size="bold"
          align="center"
          sx={{ fontSize: 32, marginBottom: 2 }}
        >
          Something went wrong
        </Typography>
        {props.secondPara && <p align="center">{props.secondPara}</p>}
        {props.thirdPara && (
          <p align="center" style={{ marginTop: 2 }}>
            {props.thirdPara}
          </p>
        )}
      </Box>
      <Button
        id={getElementId('backToHome')}
        themevariant="primary"
        style={{ marginTop: '25px' }}
        onClick={handleLogoutClick}
      >
        {props.btnText || 'Try again'}
      </Button>
      {props.btnHome && (
        <Button
          id={getElementId('backToHome')}
          themevariant="secondary"
          fullWidth
          onClick={() => {
            navigate(props.naviUrl2);
          }}
        >
          {props.btnHome}
        </Button>
      )}
    </Box>
  );
};

Errors.defaultProps = {
  naviUrl1: '/home',
};

export default Errors;
