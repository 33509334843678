import { Box, CircularProgress } from '@mui/material';
import {
  errorHandlerCreateBrowserRouter,
  withErrorBoundary,
} from 'app/errorHandler';
import ErrorFallback from 'components/errorFallback';
import { visibilityState } from 'constants/page';
import { RouterProvider, redirect } from 'react-router-dom';
import { isAuthenticated } from 'helpers/authHelper';
import withRouteLoadingHandler from './routingHOC';
import { withRouteExperimentHandler } from 'helpers/hoc';
import RoutesRoot from './routesRoot';

const pathGenerator = (path) => {
  return path
    .replace('/src/pages', '')
    .replace('layout.jsx', '')
    .replace('page.jsx', '')
    .replaceAll('{', ':')
    .replaceAll('}', '');
};

const authLoader = (visibleIf) => {
  if (visibleIf === visibilityState.authenticated) {
    if (isAuthenticated()) {
      return true;
    } else {
      return redirect('/login');
    }
  }
  if (visibleIf === visibilityState.notAuthenticated) {
    if (!isAuthenticated()) {
      return true;
    } else {
      return redirect('/home');
    }
  }
  return true;
};

const RoutesFallback = () => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="center" width={1}>
      <CircularProgress />
    </Box>
  );
};

const buildRouter = () => {
  const modules = import.meta.glob('/src/pages/**/{page,layout}.{jsx,tsx}');

  const { layoutArray, pagesArray } = Object.keys(modules).reduce(
    (previous, current) => {
      if (current.endsWith('layout.jsx') || current.endsWith('layout.tsx')) {
        previous.layoutArray.push(current);
      } else {
        previous.pagesArray.push(current);
      }

      return previous;
    },
    { layoutArray: [], pagesArray: [] }
  );

  const mainFinalRoutes = layoutArray.map((path) => ({
    path: pathGenerator(path),
    lazy: async () => {
      const md = await modules[path]();
      const loader = async (...args) => {
        const authCheck = authLoader(md.visibleIf);
        if (authCheck !== true) {
          return authCheck;
        }
        if (md.loader) {
          return md.loader(...args);
        }
        return null;
      };

      const action = async (...args) => {
        if (md.action) return md.action(...args);
        return null;
      };
      return {
        Component: withErrorBoundary(md.default, {
          fallback: <ErrorFallback />,
        }),
        loader,
        action,
      };
    },
    children: [],
    errorElement: <ErrorFallback />,
    hydrateFallbackElement: <RoutesFallback />,
  }));

  const independentRoutes = [];

  pagesArray.forEach((path) => {
    const routePath = pathGenerator(path);

    const layoutIndex = mainFinalRoutes.findIndex(({ path }) =>
      routePath.startsWith(path)
    );

    const module = modules[path];

    const pageCommonPath = {
      path: routePath || '/',
      lazy: async () => {
        const md = await module();

        const loader = async (...args) => {
          const authCheck = authLoader(md.visibleIf);
          if (authCheck !== true) {
            return authCheck;
          }
          if (md.loader) {
            return md.loader(...args);
          }
          return null;
        };

        const action = async (...args) => {
          if (md.action) return md.action(...args);
          return null;
        };
        return {
          Component: withErrorBoundary(
            withRouteLoadingHandler(withRouteExperimentHandler(md)),
            {
              fallback: <ErrorFallback />,
            }
          ),
          loader,
          action,
        };
      },
      // loader:
      errorElement: <ErrorFallback />,
      hydrateFallbackElement: <RoutesFallback />,
    };

    if (layoutIndex >= 0) {
      mainFinalRoutes[layoutIndex].children.push(pageCommonPath);
    } else {
      independentRoutes.push(pageCommonPath);
    }
  });

  const fallbackRoute = [
    {
      path: '*',
      element: <p>Not found</p>,
    },
  ];

  const rootElement = [
    {
      Component: RoutesRoot,
      children: [...mainFinalRoutes, ...independentRoutes, ...fallbackRoute],
    },
  ];

  return rootElement;
};

const browserRoutes = buildRouter();
const router = errorHandlerCreateBrowserRouter(browserRoutes);

const Routes = () => {
  return (
    <RouterProvider router={router} fallbackElement={<RoutesFallback />} />
  );
};

export default Routes;
