import React from 'react';
import ErrorBoundary from './errorBoundary';

const withErrorBoundary = (Component, errorBoundaryProps = {}) => {
  return (props) => {
    return (
      <ErrorBoundary {...errorBoundaryProps}>
        <Component {...props} />
      </ErrorBoundary>
    );
  };
};

export default withErrorBoundary;
