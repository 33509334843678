export const experimentsEnabled = {
  ['experiment-1-a']: true,
  ['experiment-1-b']: true,
};

/*
 * This is has the object of the with key experiment and value as the array of paths that has the experiment is valid
 */
export const screensToExperiment = {
  ['experiment-1-a']: ['/home'],
  ['experiment-1-b']: ['/home', '/account-details'],
};
