import React from 'react';
import Routes from './routes';
import ApolloWrapper from './apollo';
import Auth from './auth';
import { ThemeProvider } from '@mui/material/styles';
import whiteTheme from './theme/whiteTheme';
import { CssBaseline } from '@mui/material';
import gtmInitialize from './gtm';
import { useEffect } from 'react';
import { initialise as errorHandlerInitalise } from './errorHandler';
import { subscribeToAuthEvents } from 'helpers/authHelper';
import AppWrapper from 'components/appWrapper';

errorHandlerInitalise();
gtmInitialize();

const App = () => {
  useEffect(() => {
    subscribeToAuthEvents();
  }, []);
  return (
    <ThemeProvider theme={whiteTheme}>
      <CssBaseline />
      <AppWrapper>
        <ApolloWrapper>
          <Auth>
            <Routes />
          </Auth>
        </ApolloWrapper>
      </AppWrapper>
    </ThemeProvider>
  );
};

export default App;
