import tagManager from 'react-gtm-module';

const initialise = (additional = {}) => {
  if (import.meta.env.VITE_GTM_ID) {
    tagManager.initialize({
      gtmId: import.meta.env.VITE_GTM_ID,
      auth: import.meta.env.VITE_GTM_AUTH,
      preview: import.meta.env.VITE_GTM_PREVIEW,
      ...additional,
    });
  }
};

export const addVariablesToDataLayer = (key, value) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      [key]: value,
    });
  }
};

export const addUserToDataLayer = (userId) => {
  addVariablesToDataLayer('user_id', userId);
};

export const triggerEvent = (event, additionalData = {}) => {
  if (
    window.dataLayer &&
    event &&
    typeof event === 'string' &&
    typeof additionalData === 'object'
  ) {
    window.dataLayer.push({
      event,
      ...additionalData,
    });
  }
};

export default initialise;
