// if we switch from sentry to other make changes in the following file
import React from 'react';
import * as Sentry from '@sentry/react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { withErrorBoundary as withCustomErrorBoundary } from 'components/errorBoundary';

let errorHandlerCreateBrowserRouter = createBrowserRouter;
let withErrorBoundary = withCustomErrorBoundary;

export const initialise = () => {
  const sentryBrowserIntegration = () => {
    window.Sentry.addIntegration(
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      })
    );
    errorHandlerCreateBrowserRouter =
      Sentry.wrapCreateBrowserRouter(createBrowserRouter);
    withErrorBoundary = Sentry.withErrorBoundary;
  };
  if (window.Sentry && window.Sentry.addIntegration) {
    sentryBrowserIntegration();
  } else {
    window.addEventListener('sentryInitiated', sentryBrowserIntegration);
  }
};

export { errorHandlerCreateBrowserRouter, withErrorBoundary };
