export const getUser = /* GraphQL */ `
  query GetUser {
    getUser {
      dob
      email
      first
      id
      last
      phone
      __typename
    }
  }
`;
