import { useEffect, useRef } from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { getUser } from 'graphqlLiterals/queries';
import { authenticate, getSubscriptionForCustomer } from 'graphqlLiterals/rest';
import { GrantType } from 'constants/auth';
import authVar from 'reactiveVars/auth';
import { expiresSoon, getNooliIdOrNull, logout } from 'helpers/authHelper';
import { setUser } from '@sentry/react';
import { addUserToDataLayer, addVariablesToDataLayer } from 'app/gtm';

const gqlGetUser = gql(getUser);
const gqlAuthenticate = gql(authenticate);
const gqlGetSubscriptionForCustomer = gql(getSubscriptionForCustomer);

const Auth = ({ children }) => {
  const { refreshToken, ttl, refreshWithin, accessToken } =
    useReactiveVar(authVar);

  const { data: subscriptionData } = useQuery(gqlGetSubscriptionForCustomer, {
    variables: {
      params: {
        customer_id: getNooliIdOrNull(accessToken),
      },
    },
    fetchPolicy: 'cache-only',
    nextFetchPolicy: 'cache-only',
  });

  const prevUserIdRef = useRef();

  useEffect(() => {
    if (accessToken && expiresSoon(30)) {
      logout(false);
    }
  }, [accessToken]);

  useEffect(() => {
    const currentCustomerId = getNooliIdOrNull();
    if (prevUserIdRef.current !== currentCustomerId) {
      setUser({
        customerId: currentCustomerId,
      });
      addUserToDataLayer(currentCustomerId);
      prevUserIdRef.current = currentCustomerId;
    }
  }, [accessToken]);

  useEffect(() => {
    if (subscriptionData?.getSubscriptionForCustomer?.subscription) {
      if (subscriptionData.getSubscriptionForCustomer.subscription.coupon) {
        addVariablesToDataLayer(
          'coupon',
          subscriptionData.getSubscriptionForCustomer.subscription.coupon
        );
      }
      if (subscriptionData.getSubscriptionForCustomer.subscription.status) {
        addVariablesToDataLayer(
          'subscription_status',
          subscriptionData.getSubscriptionForCustomer.subscription.status
        );
      }
    }
  }, [subscriptionData]);

  const [loadUser, { data }] = useLazyQuery(gqlGetUser, {
    skip: !accessToken,
  });

  const [v] = useMutation(gqlAuthenticate, {
    variables: {
      input: {
        phoneNumber: data?.phoneNumber,
        refreshToken: refreshToken,
        grantType: GrantType.REFRESH_TOKEN,
      },
    },
  });

  useEffect(() => {
    let timeOutValue = null;
    const refreshCall = async () => {
      try {
        let result = null;
        if (!data) {
          await loadUser();
        }
        if (data?.phoneNumber && refreshToken && expiresSoon(refreshWithin)) {
          result = await v();
        }
        timeOutValue = setTimeout(
          refreshCall,
          result?.data?.login?.refreshWithin ?? refreshWithin
        );
      } catch (error) {
        console.error(error);
      }
    };
    if (refreshWithin) {
      timeOutValue = setTimeout(refreshCall, refreshWithin);
    }
    return () => {
      clearTimeout(timeOutValue);
    };
  }, [data, loadUser, v, refreshToken, ttl, refreshWithin]);

  return children;
};

export default Auth;
