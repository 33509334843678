import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import ErrorFallback from 'components/errorFallback';
import {
  Await,
  Navigate,
  ScrollRestoration,
  useLoaderData,
} from 'react-router-dom';

const withRouteLoadingHandler = (Component) => {
  return (props) => {
    const data = useLoaderData();
    return (
      <React.Suspense
        fallback={
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width={1}
          >
            <CircularProgress />
          </Box>
        }
      >
        <Await resolve={data?.data} errorElement={<ErrorFallback />}>
          {(data) => {
            if (data?.isRedirect) {
              return <Navigate to={data?.location} replace={true} />;
            }
            return <Component {...props} data={data} />;
          }}
        </Await>
        <ScrollRestoration />
      </React.Suspense>
    );
  };
};

export default withRouteLoadingHandler;
