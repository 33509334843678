export const authenticate = /* GraphQL */ `
  mutation Authenticate($input: any) {
    login(input: $input)
      @rest(
        endpoint: "endpoint"
        path: "/token"
        method: "PUT"
        bodyKey: "input"
        type: "Auth"
      ) {
      accessToken
      refreshToken
      refreshWithin
      ttl
    }
  }
`;

export const getAccessCode = /* GraphQL */ `
  mutation GetAccessCode($input: any) {
    getAccessCode(input: $input)
      @rest(path: "/code", method: "PUT", endpoint: "auth", bodyKey: "input") {
      status
    }
  }
`;

export const loginUsingAccessCode = `
mutation LoginUsingAccessCode($input: any) {
  loginUsingAccessCode(input: $input)
    @rest(
      path: "/token"
      method: "PUT"
      endpoint: "auth"
      type: "Auth"
    ){
      accessToken
      refreshToken
      refreshWithin
      ttl
    }
}
`;

export const signupCustomer = `
mutation SignupCustomer($input: any) {
  signupCustomer(input: $input)
    @rest(
      path: "/signup"
      method: "PUT"
      endpoint: "auth"
    ){
      userId
    }
}
`;

export const chargebeeCreateCustomer = `
mutation CreateChargebeeCustomer($input: any) {
  createChargebeeCustomer(input: $input)
    @rest(
      path: "/customers"
      method: "POST"
      endpoint: "chargebee"
      bodyKey: "input"
      type: "Auth"
    ){
      accessToken
      refreshToken
      refreshWithin
      ttl
      customer
    }
}
`;

export const chargebeePaymentIntent = `
mutation ChargebeePaymentIntent($input: any) {
  chargebeePaymentIntent(input: $input)
    @rest(
      path: "/payment_intent"
      method: "POST"
      endpoint: "chargebee"
      bodyKey: "input"
    ){
      payment_intent
    }
}
`;
export const createPaymentSourceWithToken = `
mutation CreatePaymentSourceWithToken($input: any) {
  createPaymentSourceWithToken(input: $input)
    @rest(
      path: "/payment_sources/create_using_temp_token"
      method: "POST"
      endpoint: "chargebee"
      bodyKey: "input"
    ){
      payment_source
      customer
    }
}
`;

export const createSubscription = `
mutation CreateSubscription($input: any, $params: any) {
  createSubscription(input: $input, param: $params)
    @rest(
      path: "/customers/{args.param.customer_id}/subscription_for_items"
      method: "POST"
      endpoint: "chargebee"
      bodyKey: "input"
    ){
      customer
      subscription
    }
}
`;

export const updateChargebeeCustomer = `
mutation updateChargebeeCustomer($input: any, $params: any) {
  updateChargebeeCustomer(input: $input, param: $params)
    @rest(
      path: "/customers/{args.param.customer_id}"
      method: "PUT"
      endpoint: "chargebee"
      bodyKey: "input"
    ){
      customer
    }
}
`;

export const getSubscriptionForCustomer = `
query GetSubscriptionForCustomer($params: any) {
  getSubscriptionForCustomer(param: $params)
    @rest(
      path: "/customers/{args.param.customer_id}/subscriptions"
      method: "GET"
      endpoint: "chargebee",
    ){
      customer
      amount_after_discount
      subscription
    }
}
`;

export const getCustomer = `
query GetCustomer {
  getCustomer
    @rest(
      path: "/customer"
      method: "GET"
      endpoint: "chargebee",
    ){
      customer
    }
}
`;

export const getTransactions = `
query GetTransactions($params: any) {
  getTransactions(param: $params)
    @rest(
      path: "/customers/{args.param.customer_id}/transactions"
      method: "GET"
      endpoint: "chargebee"
    ){
      list
      headers
      isIdempotencyReplayed 
    }
}
`;

export const subscriptionItemCancellation = `
  mutation subscriptionItemCancellation($input: any, $params: any) {
    subscriptionItemCancellation(input: $input, param: $params)
      @rest(
        path: "/subscriptions/{args.param.subscription_id}/cancel"
        method: "PUT"
        endpoint: "chargebee"
        bodyKey: "input"
      ){
        customer
        subscription
      }
  }
  `;

export const collectPayment = `
mutation CollectPayment($params: any, $input: any) {
  collectPayment(param: $params, input: $input)
    @rest(
      path: "/customers/{args.param.customer_id}/collect_payment"
      method: "POST"
      endpoint: "chargebee",
      bodyKey: "input"
    ){
      list
    }
}
`;

export const getInvoice = `
query GetInvoice($queryParams: any = {}) {
  getInvoice(queryParams: $queryParams)
    @rest(
      path: "/invoice?{args.queryParams}"
      method: "GET"
      endpoint: "chargebee"
    ){
      list
    }
}
`;

export const getPaymentSource = `
query GetPaymentSource($queryParams: any){
  getPaymentSource(queryParams: $queryParams)
    @rest(
      path: "/payment_source?{args.queryParams}"
      method: "GET",
      endpoint: "chargebee"
    ){
      payment_source
    }
}
`;

export const removeScheduledCancellation = `
mutation RemoveScheduledCancellation($input: any) {
  removeScheduledCancellation(input: $input)
    @rest(
      path: "/customers/remove_scheduled_cancellation"
      method: "PUT"
      endpoint: "chargebee"
      bodyKey: "input"
    ){
      customer
      subscription
    }
}
`;

export const reactivateCustomerSubscription = `
mutation ReactivateCustomerSubscription($input: any) {
  reactivateCustomerSubscription(input: $input)
    @rest(
      path: "/customers/reactivate_customer_subscription"
      method: "PUT"
      endpoint: "chargebee"
      bodyKey: "input"
    ){
      customer
      subscription
      invoice
    }
}
`;

export const createEstimateSubscription = `
mutation CreateEstimateSubscription($params: any, $input: any) {
  createEstimateSubscription(param: $params, input: $input)
    @rest(
      path: "/customers/{args.param.customer_id}/estimate/subscription"
      method: "POST"
      endpoint: "chargebee"
      bodyKey: "input"
    ){
      estimate
      error
    }
}
`;

export const removeCouponFromSubscription = `
mutation RemoveCouponFromSubscription($params: any, $input: any) {
  removeCouponFromSubscription(param: $params, input: $input)
    @rest(
      path: "/customers/remove_coupon"
      method: "PUT"
      endpoint: "chargebee"
      bodyKey: "input"
    ){
      customer
      invoice
      isIdempotencyReplayed
      subscription
    }
}
`;

export const updateSubscriptionForItems = `
mutation UpdateSubscriptionForItems($params: any, $input: any) {
  updateSubscriptionForItems(param: $params, input: $input)
    @rest(
      path: "/customers/update_subscription"
      method: "PUT"
      endpoint: "chargebee"
      bodyKey: "input"
    ){
      customer
      subscription
    }
}
`;
