export const GrantType = {
  ACCESS_CODE: 'access_code',
  PASSWORD: 'password',
  REFRESH_TOKEN: 'refresh_token',
  CLIENT_CREDENTIALS: 'client_credentials',
  RESET_PASSWORD: 'reset_password',
};

export const AuthUserStatus = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
};

export const HttpResponse = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 403,
  SERVER_ERROR: 500,
};
