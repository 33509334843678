import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  useReactiveVar,
} from '@apollo/client';

import { RetryLink } from '@apollo/client/link/retry';
import { RestLink } from 'apollo-link-rest';

import { HttpResponse } from 'constants/auth';
import authVars from 'reactiveVars/auth';

const memoryCache = new InMemoryCache();

export const getClient = (token) => {
  const httpLink = new HttpLink({
    uri: import.meta.env.VITE_AWS_ANALYTICS_GRAPHQL_ENDPOINT,
    headers: {
      Authorization: token,
    },
  });

  const restLink = new RestLink({
    uri: import.meta.env.VITE_AUTH_URL,
    endpoints: {
      auth: import.meta.env.VITE_AUTH_URL,
      chargebee: import.meta.env.VITE_CHARGEEBEE_URL,
    },
    typePatcher: {
      Auth: (data) => {
        localStorage.setItem(
          import.meta.env.VITE_ACCESS_TOKEN_KEY,
          data.accessToken
        );
        localStorage.setItem(
          import.meta.env.VITE_REFRESH_TOKEN_KEY,
          data.refreshToken
        );
        authVars({
          ...authVars(),
          ...data,
        });
        return data;
      },
    },
    responseTransformer: async (response) => {
      const jsonResponse = await response.json();
      return jsonResponse;
    },
    headers: {
      ...(token
        ? {
            Authorization: `JWT ${token}`,
          }
        : {}),
    },
  });

  const retryLink = new RetryLink({
    attempts: (count, operation, error) => {
      const responseStatus =
        error && error.response && error.response.status
          ? error.response.status
          : null;
      // return 401 requests only once
      if (
        !!error &&
        responseStatus === HttpResponse.UNAUTHORIZED &&
        count > 1
      ) {
        return false;
      }
      if (count > 10) {
        return false;
      }
      return !!error;
    },
    delay: (count) => {
      return count * 1000 * Math.random();
    },
  });

  const client = new ApolloClient({
    link: ApolloLink.from([restLink, retryLink, httpLink]),
    cache: memoryCache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
      },
      query: {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
      },
    },
  });

  return client;
};

const ApolloWrapper = ({ children }) => {
  const { accessToken } = useReactiveVar(authVars);
  const client = getClient(accessToken);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
