import { ApolloError } from '@apollo/client';
import auth from 'reactiveVars/auth';
import { getClient } from 'app/apollo/apollo';
import { elementType } from 'constants/elements';

export const errorHasStatusCode = (error, statusCode) => {
  if (!(error instanceof ApolloError) || !error.networkError) {
    return false;
  }

  return error.networkError.statusCode === statusCode;
};

export const isAndroid = () => {
  return /android/i.test(navigator.userAgent);
};

export const isIOS = () => {
  return /iPhone|iPod|iPod/i.test(navigator.userAgent);
};

export const getChargebeeSubscriptionStatus = (subscription, invoices) => {
  if (subscription.status == 'active') {
    if (['not_paid', 'payment_due'].includes(invoices?.[0]?.invoice?.status)) {
      return {
        status: 'Payment failed',
        text:
          'We were <strong>unable to collect</strong> your  <strong>subscription payment</strong> on ' +
          new Date(invoices?.[0].invoice?.date * 1000).toLocaleDateString(
            'en-GB',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }
          ) +
          '. Please try again or change your payment method. ',
      };
    } else {
      return {
        status: 'Active',
        text: '',
      };
    }
  } else if (subscription.status == 'in_trial' && subscription?.cancelled_at) {
    return {
      status: 'Cancelled',
      text:
        'You still have access to your Nooli subscription until <strong>' +
        new Date(subscription.trial_end * 1000).toLocaleDateString('en-GB', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }) +
        '</strong>. When your <strong>free trial ends.</strong>',
    };
  } else if (subscription.status == 'in_trial' && !subscription?.cancelled_at) {
    return {
      status: 'In trial',
      text:
        'You are currently with in free trial period and your first payment will be taken automatically on ' +
        new Date(subscription.next_billing_at * 1000).toLocaleDateString(
          'en-GB',
          {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }
        ) +
        '.',
    };
  } else if (subscription.status == 'non_renewing') {
    return {
      status: 'Cancelled',
      text:
        'Your subscription will be cancelled at the end of your current billing period on <strong>' +
        new Date(subscription.current_term_end * 1000).toLocaleDateString(
          'en-GB',
          {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }
        ) +
        '</strong>.',
    };
  } else if (subscription.status == 'cancelled') {
    if (invoices?.length == 0) {
      return {
        status: 'Cancelled',
        text:
          'Your free trial ended on <strong>' +
          new Date(subscription.trial_end * 1000).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }) +
          '</strong>.',
      };
    } else {
      return {
        status: 'Cancelled',
        text:
          'Your subscription was cancelled on <strong>' +
          new Date(subscription.cancelled_at * 1000).toLocaleDateString(
            'en-GB',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }
          ) +
          '</strong>.',
      };
    }
  } else
    return {
      status: subscription.status,
      text: '',
    };
};

export const getApolloClient = () => {
  return getClient(auth().accessToken);
};

export const getCustomerSubscriptionStatus = (subscription) => {
  if (subscription.status == 'active') {
    return {
      status: 'Monthly Premium',
    };
  } else if (
    subscription.status == 'in_trial' &&
    !subscription.cancel_schedule_created_at
  ) {
    return {
      status: 'Monthly Premium',
    };
  } else if (
    subscription.status == 'in_trial' &&
    subscription.cancel_schedule_created_at
  ) {
    return {
      status: 'Cancelled',
    };
  } else if (subscription.status == 'non_renewing') {
    return {
      status: 'Cancelled',
    };
  } else if (subscription.status == 'cancelled') {
    return {
      status: 'Cancelled',
    };
  } else
    return {
      status: subscription.status,
    };
};

export const getCurrencySymbol = (currencyCode) => {
  // Temp solution to get currency symbol.
  // Alternative: use react packages to get currency code
  switch (currencyCode) {
    case 'GBP':
      return '£';
    default:
      return '£';
  }
};

export const getElementId = (
  uniqueIDRepresentation,
  type = elementType.button,
  withCurrentPath = true
) => {
  if (withCurrentPath) {
    return `${window.location.pathname}-${type}-${uniqueIDRepresentation}`;
  }
  return `${type}-${uniqueIDRepresentation}`;
};

export const componentCase = (str = '') => {
  return str
    .replace(/[-_]+$/, '')
    .replace(/[-_](.)/g, (_, c) => c.toUpperCase())
    .replace(/(\d+)([a-zA-Z])/g, (_, num, char) => num + char.toUpperCase())
    .replace(/^\w/, (c) => c.toUpperCase());
};
