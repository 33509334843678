import React from 'react';
import { Box } from '@mui/material';
import FooterLogo from 'assets/images/coachVista.png';

function FooterImage() {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <img src={FooterLogo} alt="footer-image" />
    </Box>
  );
}

export default FooterImage;
