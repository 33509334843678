import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { componentCase } from './functions';
import { experimentsEnabled } from 'constants/experiments';
import { triggerEvent } from 'app/gtm';

export const withRouteExperimentHandler = (md) => {
  return (props) => {
    const location = useLocation();
    const [, setSearchParams] = useSearchParams();
    const previousExperimentToShow = useRef(null);

    const experimentToShow = useMemo(
      () =>
        experimentsEnabled[
          localStorage.getItem(`experiment-${location.pathname}`)
        ]
          ? localStorage.getItem(`experiment-${location.pathname}`)
          : false,
      [location.pathname]
    );

    useEffect(() => {
      if (
        previousExperimentToShow.current !== experimentToShow &&
        experimentToShow &&
        md[componentCase(experimentToShow)]
      ) {
        previousExperimentToShow.current = experimentToShow;
        triggerEvent(experimentToShow);

        setSearchParams({
          v: experimentToShow,
        });
      }
    }, [experimentToShow, setSearchParams]);

    if (experimentToShow && md[componentCase(experimentToShow)]) {
      const Component = md[componentCase(experimentToShow)];
      return <Component {...props} />;
    }

    return <md.default {...props} />;
  };
};
